import React from "react";
import { useState, useEffect } from "react";
import logo from "./assets/logo.svg";
import "./App.css";
import "@picocss/pico";

function App() {
  const [input, setInput] = useState("");
  const [page, setPage] = useState("");
  // ===
  // Poor Man's Router
  // ===
  useEffect(() => {
    setPage(window.location.pathname);
  }, []);

  useEffect(() => {
    console.log({ input });
  }, [input]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>Bevel Time</h3>
        <p>
          Welcome to the time tracker. Enter your key in the input below to get
          started.
        </p>
        <form>
          <input
            type="text"
            placeholder="Enter your key"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Submit{" "}
          </button>
        </form>
        <p>
          Don't have a key shoot the{" "}
          <a href="mailto:team@bevel.work">team an email</a> to get one
          generated
        </p>
      </header>
    </div>
  );
}

export default App;
